import React from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { Box, Grid, Typography } from "@mui/material";
import Select from "../Select";
import { Subtitle, Button, styles } from "./ExportFileSidebarStyle";
import useExportFileSidebarLogic from "./useExportFileSidebarLogic";
import { EFileTypes } from "shared/constants/optionsConstants";
import FormInputDate from "../FormInputs/Date/FormInputDate";
import { EXPORT_ATTENDANCE_TYPE } from "shared/constants";

const ExportFileSidebar = ({
  isOpen,
  setIsOpen,
  onClose,
  loading,
  success,
  error,
  handleSingleDayExport,
  ...props
}) => {
  const { onExportHistory, selectedFileType, onSelectedFileType, onSingleDayExport, control } =
    useExportFileSidebarLogic({
      handleSingleDayExport,
      ...props
    });

  return (
    <Sidebar
      history={history}
      isOpen={isOpen}
      isDrawer={true}
      setIsOpen={setIsOpen}
      onResolve={onClose}
      resolveLabel={"Close"}
      isLoading={loading}
      isSuccess={success}
      error={error}
      showCancelButton={false}
    >
      <Box>
        <Typography variant="h5" fontWeight="700">
          {"Export"}
        </Typography>
      </Box>

      <Grid container rowSpacing={1} spacing={2} mb={3}>
        <Grid item width="100%" mt={2} mb={2}>
          <Divider color="#8D9096" />
        </Grid>

        <Grid item width="100%" mt={1} mb={1}>
          <Subtitle variant="h6" fontWeight="normal">
            {"File type for export"}
          </Subtitle>
        </Grid>

        <Grid item xs={12}>
          <Box width={"50%"} pr={2}>
            <Select
              selectedValue={selectedFileType}
              handleChange={onSelectedFileType}
              values={EFileTypes}
              fullWidth={false}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              selectStyles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              disabled={true}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <FormInputDate control={control} label="Start date" name="startDate" fullWidth />
        </Grid>

        <Grid item xs={6}>
          <FormInputDate control={control} label="End date" name="endDate" fullWidth />
        </Grid>

        <Grid item width="100%" mt={2}>
          <Divider color="#8D9096" />
        </Grid>

        <Grid item width="100%" mt={1}>
          <Subtitle variant="h6" fontWeight="normal">
            {"Attendance data"}
          </Subtitle>
        </Grid>

        {!!handleSingleDayExport && (
          <>
            <Grid item xs={6} display={"flex"} alignItems={"flex-end"}>
              <Box width={"100%"}>
                <Button onClick={onSingleDayExport}>{"Single day detailed export"}</Button>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormInputDate control={control} label="Select date" name="singleDate" fullWidth />
            </Grid>

            <Grid item width="100%" mt={2}>
              <Divider color="#8D9096" />
            </Grid>
          </>
        )}

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button onClick={onExportHistory()} disabled={loading} title="Historic export">
              {"Historic export"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.daysPerWeek)}
              disabled={loading}
              title="Department - avg. days per week"
            >
              {"Department - avg. days per week"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.employeesPerDay)}
              disabled={loading}
              title="Department - avg. empl. per day"
            >
              <Typography variant="span">{"Department - avg. empl. per day"}</Typography>
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
                onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.employeesPlan)}
                disabled={loading}
                title="Employees plan"
            >
              {"Employees plan"}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
                onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.workstationsPlan)}
                disabled={loading}
                title="Workstations plan"
            >
              {"Workstations plan"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Sidebar>
  );
};

ExportFileSidebar.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  success: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
  handleExportHistory: PropTypes.func,
  handleSingleDayExport: PropTypes.func,
  error: PropTypes.any
};

export default ExportFileSidebar;
